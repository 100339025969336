export default function () {
    const countItems = document.querySelector('.detail-amount');
    const pricePerMeterDiv = document.querySelector(".detail-price-per-meter");
    const inputField = countItems.querySelector('input');

    showTotal();

    if (countItems) {
        countItems.querySelector('.plus').addEventListener('click', function () {
            inputField.value = Number(inputField.value) + 1;
            showTotal();
        }, false);
        countItems.querySelector('.plus').addEventListener('keydown', function (e) {
            if (e.key === 'Enter') {
                this.click();
            }
        }, false);

        countItems.querySelector('.minus').addEventListener('click', function () {
            if (Number(inputField.value) > 1) {
                inputField.value = Number(inputField.value) - 1;
                showTotal();
            }
        }, false);
        countItems.querySelector('.minus').addEventListener('keydown', function (e) {
            if (e.key === 'Enter') {
                this.click();
            }
        }, false);
    }
    function showTotal() {
        if (!pricePerMeterDiv)
            return;
        const metersInPiece = parseFloat(pricePerMeterDiv.dataset.metersInPiece);
        const total = metersInPiece * Number(inputField.value);
        document.querySelector(".total-price-per-meter").innerText = total.toFixed(2).toString().replace(".",",");

    }

}
